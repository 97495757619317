import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import fireImg from "../../assets/images/hot-rates.svg"

export default function Rates() {
  return (
    <StaticQuery
      query={graphql`
        query RatesQuery {
          allStrapiRate {
            edges {
              node {
                Lender
                ComparisonRate
                AdvertisedRate
                id
              }
            }
          }
        }
      `}
      render={data => (
        <div className="ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2>Hot rates in Australia today</h2>
                </div>

                <div className="rate-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <span className="title lender">Lender</span>
                        </th>
                        <th className="special">
                          <span className="title">
                            Advertised <br />
                            Rate From<sup className="hash">#</sup>
                          </span>
                        </th>
                        <th>
                          <span className="title">
                            Comparison <br />
                            Rate From<i className="asterisk">*</i>
                          </span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.allStrapiRate.edges.map(document => (
                        <tr key={document.node.id}>
                          <td>
                            <span className="data bank">
                              {document.node.Lender}
                            </span>
                          </td>
                          <td className="special">
                            <span className="data advertised">
                              {document.node.AdvertisedRate}%
                            </span>
                          </td>
                          <td>
                            <span className="data comparison">
                              {document.node.ComparisonRate}%
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="ml-lg-auto col-lg-6">
                <div className="custom-card rate-card">
                  <div className="rate-card-title">
                    <div className="icon">
                      <img src={fireImg} alt="Hot Rates" />
                    </div>
                    <h3>
                      <span className="highlight">Oxygen</span> offers the
                      hottest rates on the market!
                    </h3>
                  </div>
                  <p>
                    If you are paying a higher rate, talk to us about a better
                    deal.
                  </p>
                  <Link
                    to="/book-a-call"
                    className="default-btn primary-btn btn-lg mb-2 mx-1"
                  >
                    Talk to an Expert <span></span>
                  </Link>
                  <Link
                    to="/contact"
                    className="default-btn outline-btn btn-lg mb-2 mx-1"
                  >
                    Contact Us <span></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="disclaimer">
              #DISCLAIMER: Lowest rates are typically based on Owner Occupier
              with LVR &lt;70%, Full Doc, P&amp;I payments, with either a fixed
              or variable rate from the Lender. Rates subject to change without
              notice. Subject to specific lender requirements including but not
              limited to; type of home loan product selected, borrower credit
              assessment, responsible lending policy, and terms and conditions.
              The interest rate is published on a per annum basis. *The
              Comparison Rate is on a per annum basis. The comparison rate is
              calculated for a secured loan for an amount of $150,000 over 25
              years. This comparison rate is true only for the examples given
              and may not include all fees and charges. Different terms, fees or
              other loan amounts might result in a different comparison rate.
            </div>
          </div>
        </div>
      )}
    />
  )
}
