import React from "react"
import ReactWOW from "react-wow"
// import lender1 from "../../assets/images/lenders/1.png"
// import lender2 from "../../assets/images/lenders/2.png"
// import lender3 from "../../assets/images/lenders/3.png"
// import lender4 from "../../assets/images/lenders/4.png"
// import lender5 from "../../assets/images/lenders/5.png"
// import lender6 from "../../assets/images/lenders/6.png"
// import lender7 from "../../assets/images/lenders/7.png"
// import lender8 from "../../assets/images/lenders/8.png"
// import lender9 from "../../assets/images/lenders/9.png"
// import lender10 from "../../assets/images/lenders/10.png"
// import lender11 from "../../assets/images/lenders/11.png"
// import lender12 from "../../assets/images/lenders/12.png"
// import lender13 from "../../assets/images/lenders/13.png"
// import lender14 from "../../assets/images/lenders/14.png"
// import lender15 from "../../assets/images/lenders/15.png"
// import lender16 from "../../assets/images/lenders/16.png"
// import lender17 from "../../assets/images/lenders/17.png"
// import lender18 from "../../assets/images/lenders/18.png"
// import lender19 from "../../assets/images/lenders/19.png"
// import lender20 from "../../assets/images/lenders/20.png"
// import lender21 from "../../assets/images/lenders/21.png"
// import lender22 from "../../assets/images/lenders/22.png"
// import lender23 from "../../assets/images/lenders/23.png"
// import lender24 from "../../assets/images/lenders/24.png"
// import lender25 from "../../assets/images/lenders/25.png"
// import lender26 from "../../assets/images/lenders/26.png"
// import lender27 from "../../assets/images/lenders/27.png"
// import lender28 from "../../assets/images/lenders/28.png"
// import lender29 from "../../assets/images/lenders/29.png"
// import lender30 from "../../assets/images/lenders/30.png"
// import lender31 from "../../assets/images/lenders/31.png"
// import lender32 from "../../assets/images/lenders/32.png"
// import lender33 from "../../assets/images/lenders/33.png"
// import lender34 from "../../assets/images/lenders/34.png"
import { StaticImage } from "gatsby-plugin-image"

const Partners = () => {
  return (
    <div className="partner-area pt-100 pb-70 mb-5">
      <div className="container">
        <div className="section-title">
          <h2>Our Partners</h2>
          <p>
            They make our unique style of services possible.
          </p>
        </div>
        <div className="partner-logos">
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-activepipe.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-afg.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-agent-box.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-aircall.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-aws.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-docu-sign.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-effi.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-equifax.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-fullstory.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-green-id.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".4s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-illion.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>

          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="../../assets/images/calculators/lenders/logo-quickli.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              <StaticImage
                src="https://oxygen-website-images.s3.ap-southeast-2.amazonaws.com/logo-residz.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>

          
        </div>
      </div>
    </div>
  )
}

export default Partners
