import React from "react"
import easy from "../../assets/images/icon-easy-online.svg"
import expert from "../../assets/images/icon-expert-advice.svg"
import independent from "../../assets/images/icon-independently-owned.svg"
import nofees from "../../assets/images/icon-no-hidden-fees.svg"

const OurDifference = () => {
  return (
    <section className="difference-area pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mb-5">
            <div className="section-title">
              <h2>Why We Are Different</h2>
            </div>
            <p className="intro-text">
              Oxygen saves you time, gives you choice, and helps you avoid
              pitfalls when it comes to finance. We work for you, not the banks.
              And, we go in to bat for you to find and negotiate the most
              competitive loan.
            </p>
          </div>
          <div className="col-lg-6 ml-lg-auto">
            <div className="row">
              <div className="col-sm-6">
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={independent} alt="icon" />
                  </div>
                  <h3>Independently owned and operated</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={easy} alt="icon" />
                  </div>
                  <h3>Easy online options for faster applications</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={nofees} alt="icon" />
                  </div>
                  <h3>No Hidden Fees</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={expert} alt="icon" />
                  </div>
                  <h3>Expert Advice</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurDifference
