import React from "react"
import ReactWOW from "react-wow"
// import lender1 from "../../assets/images/lenders/1.png"
// import lender2 from "../../assets/images/lenders/2.png"
// import lender3 from "../../assets/images/lenders/3.png"
// import lender4 from "../../assets/images/lenders/4.png"
// import lender5 from "../../assets/images/lenders/5.png"
// import lender6 from "../../assets/images/lenders/6.png"
// import lender7 from "../../assets/images/lenders/7.png"
// import lender8 from "../../assets/images/lenders/8.png"
// import lender9 from "../../assets/images/lenders/9.png"
// import lender10 from "../../assets/images/lenders/10.png"
// import lender11 from "../../assets/images/lenders/11.png"
// import lender12 from "../../assets/images/lenders/12.png"
// import lender13 from "../../assets/images/lenders/13.png"
// import lender14 from "../../assets/images/lenders/14.png"
// import lender15 from "../../assets/images/lenders/15.png"
// import lender16 from "../../assets/images/lenders/16.png"
// import lender17 from "../../assets/images/lenders/17.png"
// import lender18 from "../../assets/images/lenders/18.png"
// import lender19 from "../../assets/images/lenders/19.png"
// import lender20 from "../../assets/images/lenders/20.png"
// import lender21 from "../../assets/images/lenders/21.png"
// import lender22 from "../../assets/images/lenders/22.png"
// import lender23 from "../../assets/images/lenders/23.png"
// import lender24 from "../../assets/images/lenders/24.png"
// import lender25 from "../../assets/images/lenders/25.png"
// import lender26 from "../../assets/images/lenders/26.png"
// import lender27 from "../../assets/images/lenders/27.png"
// import lender28 from "../../assets/images/lenders/28.png"
// import lender29 from "../../assets/images/lenders/29.png"
// import lender30 from "../../assets/images/lenders/30.png"
// import lender31 from "../../assets/images/lenders/31.png"
// import lender32 from "../../assets/images/lenders/32.png"
// import lender33 from "../../assets/images/lenders/33.png"
// import lender34 from "../../assets/images/lenders/34.png"
import { StaticImage } from "gatsby-plugin-image"

const Lender = () => {
  return (
    <div className="partner-area pt-100 pb-70 mb-5">
      <div className="container">
        <div className="section-title">
          <h2>Our Lenders</h2>
          <p>
            We compare hundreds of loans for you from our network of lenders.
          </p>
        </div>
        <div className="partner-logos">
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender13} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/13.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender33} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/33.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender4} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/4.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender25} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/25.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender30} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/30.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender9} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/9.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender14} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/14.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender6} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/6.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender1} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/1.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender2} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/2.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".4s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender3} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/3.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>

          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender5} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/5.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>

          <ReactWOW delay=".4s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender7} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/7.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender8} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/8.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>

          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender10} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/10.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".4s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender11} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/11.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender12} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/12.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>

          <ReactWOW delay=".4s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender15} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/15.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender16} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/16.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender17} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/17.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender18} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/18.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".4s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender19} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/19.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender20} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/20.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender21} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/21.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender22} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/22.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".4s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender23} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/23.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender24} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/24.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>

          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender26} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/26.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".4s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender27} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/27.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender28} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/28.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".2s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender29} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/29.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>

          <ReactWOW delay=".4s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender31} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/31.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
          <ReactWOW delay=".1s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender32} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/32.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>

          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="single-lender-item">
              {/* <img src={lender34} alt="lender" /> */}
              <StaticImage
                src="../../assets/images/lenders/34.png"
                loading="lazy"
                alt="lender"
                formats={["auto", "webp"]}
                placeholder="tracedSVG"
              />
            </div>
          </ReactWOW>
        </div>
      </div>
    </div>
  )
}

export default Lender
